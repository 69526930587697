<template>
  <div class="mobile" v-loading="isLoading">
    <div class="mobile-header padding-lr flex justify-between">
      <div class="mobile-header-left flex align-center">
        <div class="mobile-header-left-img border-round-8 text-center">
          <img
            class="border-round-8"
            v-if="monthPlanEntity?.avatar"
            :src="monthPlanEntity?.avatar"
          />
          <span v-else> {{ monthPlanEntity?.publishName?.slice(-2) }}</span>
        </div>
        <div class="mobile-header-employee">
          <p class="mobile-header-employee-name">
            {{ monthPlanEntity.publishName }}
          </p>
          <span class="mobile-header-employee-position">
            {{ monthPlanEntity.departmentName }} ——
            {{ monthPlanEntity.position }}</span
          >
        </div>
      </div>
      <div class="mobile-header-right">
        <span class="mobile-header-month">{{ monthPlanEntity.month }}</span>
      </div>
    </div>
    <div class="mobile-content">
      <el-form :model="planList" ref="planRef">
        <div
          class="
            bg-white
            border-round-12
            padding-tb padding-lr
            margin-top-sm margin-lr
          "
          v-for="(item, index) in planList.info"
          :key="index"
        >
          <div class="flex justify-between margin-bottom">
            <div>目标({{ index + 1 }})</div>
          </div>
          <el-form-item
            :prop="'info.' + index + '.corePerformanceIndicators'"
            label="核心工作指标 :"
          >
            <div>{{ item.corePerformanceIndicators }}</div>
          </el-form-item>
          <el-form-item
            :prop="'info.' + index + '.indicatorsStandardValue'"
            label="指标标准值 :"
          >
            <div>{{ item.indicatorsStandardValue }}</div>
          </el-form-item>
          <el-form-item
            :prop="'info.' + index + '.standardEvaluation'"
            label="评分标准 :"
          >
            <div>{{ item.standardEvaluation }}</div>
          </el-form-item>
          <el-form-item
            :prop="'info.' + index + '.planTime'"
            label="计划用时(h) :"
          >
            <div>{{ item.planTime }}</div>
          </el-form-item>
          <el-form-item
            :prop="'info.' + index + '.weight'"
            label="权重(配分100) :"
          >
            <div>{{ item.weight }}</div>
          </el-form-item>
          <el-form-item
            :prop="'info.' + index + '.dataFromDepartment'"
            label="数据来源部门 :"
          >
            <div>{{ item.dataFromDepartment }}</div>
          </el-form-item>
        </div>

        <!--            v-if="planState>4||planList.selfAssessAdvantage"-->
        <div
          v-if="planState > 4 || planList.selfAssessAdvantage"
          class="
            bg-white
            border-round-12
            padding-tb padding-lr
            margin-top-sm margin-lr
          "
        >
          <!--          <div class=" margin-bottom-lg ">-->
          <!--            <el-form-item-->
          <!--              prop="note"-->
          <!--              label="各项目标完成情况简述:"-->
          <!--            >-->

          <!--              <div >{{ planList.note }}</div>-->
          <!--            </el-form-item>-->
          <!--          </div>-->
          <div class="flex justify-between">
            <p>自我评价</p>
            <div class="fraction">{{ planList.selfAssessScore }}</div>
          </div>
          <el-form-item prop="selfAssessAdvantage" label="优势 :">
            <div>{{ planList.selfAssessAdvantage }}</div>
          </el-form-item>
          <el-form-item prop="selfAssessImprovement" label="劣势 :">
            <div>{{ planList.selfAssessImprovement }}</div>
          </el-form-item>
        </div>
        <!--      会审列表-->
        <div
          v-if="openReview === 0"
          class="
            bg-white
            border-round-12
            padding-tb
            box-ing
            padding-lr
            margin-top-sm margin-lr
          "
        >
          <div class="margin-bottom flex justify-between align-center">
            <p>会审复评</p>
            <div class="flex align-center justify-center">
              <span style="font-size: 16px; color: rgba(0, 0, 0, 0.45)"
                >得分均值:</span
              ><span class="fraction">{{ sum }}</span>
            </div>
          </div>
          <template v-if="planList.jointAssessEntities.length === 0">
            <el-form-item
              prop="jointReviewScore"
              :rules="[
                { required: true, message: '得分必填', trigger: 'blur' },
              ]"
              label="复核得分"
            >
              <el-input-number
                v-model="planList.jointReviewScore"
                placeholder="请输入复核得分"
                :min="0"
                :precision="2"
              />
            </el-form-item>
            <el-form-item
              prop="jointReviewAdvantage"
              label="会审意见 :"
              :rules="[
                { required: true, message: '优势必填', trigger: 'blur' },
              ]"
            >
              <el-input
                type="textarea"
                placeholder="会审意见"
                v-model="planList.jointReviewAdvantage"
                maxlength="200"
                show-word-limit
              />
            </el-form-item>
          </template>
          <div
            v-else
            v-for="(item, index) in planList.jointAssessEntities"
            :key="index"
            class="jointHearing padding-top-sm"
          >
            <div>
              <div class="flex align-center">
                <div class="jointHearing-img flex align-center justify-center">
                  <img
                    v-if="item.avatar && item.avatar?.length !== 0"
                    :src="item?.avatar"
                  />
                  <span v-else> {{ item.jointPerson.slice(-2) }}</span>
                </div>
                <p class="jointHearing-name margin-left">
                  {{ item.jointPerson }}
                </p>
              </div>
              <div class="fraction">{{ item.jointReviewScore }}</div>
            </div>
            <el-form-item label="会审意见 :">
              <div>{{ item.jointReviewAdvantage }}</div>
            </el-form-item>
          </div>
        </div>
        <!--上级复评-->
        <div
          v-if="
            planState > 5 &&
            (planList.reviewEntityList.length > 0 ||
              isJoinAssessor === 0 ||
              isJoinAssessor === 2)
          "
          class="
            bg-white
            border-round-12
            padding-tb padding-lr
            margin-top-sm margin-lr
          "
        >
          <div
            class="flex jointHearing padding-top"
            v-if="planList.reviewEntityList.length > 0"
          >
            <p>复评</p>
          </div>
          <div
            v-for="(item, index) in planList.reviewEntityList"
            :key="index"
            class="padding-top-lg"
          >
            <div class="flex justify-between">
              <div class="flex align-center">
                <!-- <div class="jointHearing-img flex align-center justify-center ">
                  <img v-if="item.avatar&&item.avatar?.length!==0" :src="item?.avatar" />
                  <span v-else> {{ item.reviewName?.slice(-2) }}</span>
                </div> -->
                <p class="jointHearing-name margin-left">
                  {{ item.reviewName }}
                </p>
              </div>
              <div class="fraction">{{ item.superiorReviewScore }}</div>
            </div>
            <el-form-item label="优势 :">
              <div>{{ item.superiorReviewAdvantage }}</div>
            </el-form-item>
            <el-form-item label="劣势 :">
              <div>{{ item.superiorReviewImprovement }}</div>
            </el-form-item>
          </div>
          <div
            v-if="planList.openReview === 1"
            class="
              bg-white
              border-round-12
              jointHearing
              padding-tb
              box-ing
              margin-top
            "
          >
            <div class="flex">
              <p>上级复评</p>
            </div>
            <el-form-item
              prop="superiorReviewScore"
              :rules="[
                { required: true, message: '得分必填', trigger: 'blur' },
              ]"
              label="复核得分"
            >
              <el-input-number
                placeholder="请输入复核得分"
                v-model="planList.superiorReviewScore"
                :min="0"
                :precision="2"
              />
            </el-form-item>
            <el-form-item
              prop="superiorReviewAdvantage"
              label="优势 :"
              :rules="[
                {
                  required: isJoinAssessor !== 2,
                  message: '优势必填',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                type="textarea"
                placeholder="请输入优势"
                v-model="planList.superiorReviewAdvantage"
                maxlength="200"
                show-word-limit
              />
            </el-form-item>
            <el-form-item
              prop="superiorReviewImprovement"
              label="劣势 :"
              :rules="[
                {
                  required: isJoinAssessor !== 2,
                  message: '优势必填',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                type="textarea"
                placeholder="请输入劣势"
                v-model="planList.superiorReviewImprovement"
                maxlength="200"
                show-word-limit
              />
            </el-form-item>
          </div>
        </div>
      </el-form>
      <Flow class="margin-lr" :propsPlanState="planState" />
    </div>
    <div class="mobile-footer flex bg-white justify-between">
      <div style="height: 32px">
        <!--菜单功能-->
      </div>
      <div class="flex justify-center">
        <div v-if="store.state.isShow">
          <el-button @click="open('拒绝')" v-if="isJoinAssessor !== 1"
            >拒绝</el-button
          >
          <el-button type="primary" @click="open('通过')">通过</el-button>
        </div>
        <div v-if="planState === 6">
          <!--  会审提交-->
          <el-button
            :isLoading="isLoading"
            type="primary"
            v-if="
              planList.jointAssessEntities.length === 0 && isJoinAssessor === 1
            "
            @click="onJointSave"
          >
            提交
          </el-button>
          <!--  复审通过-->
          <el-button
            type="primary"
            :isLoading="isLoading"
            v-if="planList.openReview === 1"
            @click="onUpdateMonthPlan"
          >
            提交
          </el-button>
        </div>
      </div>
    </div>
    <el-dialog
      :title="titleType + '意见'"
      v-model="dialogFormVisible"
      :before-close="cancel"
      width="80%"
    >
      <el-form :model="form" ref="formRef">
        <el-form-item
          prop="remark"
          :rules="[
            {
              required: title === '拒绝',
              message: '拒绝理由必填',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            type="textarea"
            placeholder="请输入"
            v-model="form.remark"
            maxlength="200"
            show-word-limit
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="determine">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import { nextTick, onBeforeMount, ref, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import {
  jointSave,
  seeDetail,
  updateMonthPlan,
  updatePlanState
} from '@/api/user'
import { ElMessage } from 'element-plus'
import { useStore } from '@/vuex/store'
import Flow from '@/components/Flow'
export default {
  name: 'MobileApproval',
  components: {
    Flow
  },
  setup () {
    const route = useRoute()
    const isLoading = ref(false)
    const isJoinAssessor = ref(5)
    const monthPlanEntity = ref({})
    const planRef = ref(null)
    const formRef = ref(null)
    const form = ref({
      remark: ''
    })
    const titleType = ref('审批')
    const planList = ref({
      info: [],
      reviewEntityList: [],
      jointAssessEntities: [],
      selfAssessAdvantage: '',
      selfAssessImprovement: '',
      // 上级劣势
      superiorReviewImprovement: '',
      // 上级优势
      superiorReviewAdvantage: '',
      // 上级打分
      superiorReviewScore: undefined,
      publishName: '',
      departmentName: '',
      position: '',
      selfAssessScore: 0,
      jointReviewScore: undefined,
      jointReviewAdvantage: '',
      openReview: 1
    })
    console.log(route.query)
    const title = ref('通过')
    const planState = ref(1)
    const sum = ref(0)
    const dialogFormVisible = ref(false)
    const store = useStore()
    // 上级审批
    const getDetail = () => {
      isLoading.value = true
      const { id, userid } = route.query
      seeDetail({
        userid: userid || store.state.user.userid,
        planId: id
      }).then((res) => {
        isLoading.value = false
        if (res.code === 200) {
          isJoinAssessor.value = res.data.isJoinAssessor
          if (res.data.isJoinAssessor === 1) {
            titleType.value = '会审意见'
          }
          if (res.data.isJoinAssessor === 0 || res.data.isJoinAssessor === 2) {
            titleType.value = '审批意见'
          }
          monthPlanEntity.value = res.data.monthPlanEntity
          planState.value = res.data.monthPlanEntity.planState
          planList.value = res.data.monthPlanEntity
          planList.value.info = res.data.monthPlanItemEntityList
          planList.value.jointAssessEntities =
            res.data.jointAssessEntities || []
          planList.value.reviewEntityList = res.data.reviewEntityList || []
          planList.value.openReview = res.data.openReview
          let stateTitle = '计划'
          if (res.data.monthPlanEntity.planState > 4) {
            stateTitle = '月总结'
          }
          document.title = `${res.data.monthPlanEntity.month}绩效${stateTitle}`
          if (
            res.data.jointAssessEntities &&
            res.data.jointAssessEntities.length > 0
          ) {
            let average = 0
            res.data.jointAssessEntities.forEach((item) => {
              average += Number(item.jointReviewScore)
            })
            sum.value = Math.round(
              average / res.data.jointAssessEntities.length
            )
            // planList.value.superiorReviewScore = Math.round(average / res.data.jointAssessEntities.length)
          }
        }
      })
    }
    // 上级提交
    const onUpdateMonthPlan = () => {
      planRef.value.validate((valid) => {
        if (valid) {
          const {
            superiorReviewImprovement,
            superiorReviewAdvantage,
            superiorReviewScore
          } = planList.value
          if (superiorReviewScore > 150) {
            return ElMessage.error('复合得分最大值为150')
          }
          isLoading.value = true
          updateMonthPlan({
            reviewUserid: route.query.userid || store.state.user.userid,
            planId: route.query.id,
            // 上级劣势
            superiorReviewImprovement,
            // 上级优势
            superiorReviewAdvantage,
            // 上级打分
            superiorReviewScore
          }).then((res) => {
            isLoading.value = false
            if (res.code === 200) {
              isLoading.value = true
              updatePlanState({
                planId: route.query.id,
                checkResult: 1,
                approvePersonId: route.query.userid || store.state.user.userid
              }).then((res) => {
                isLoading.value = false
                if (res.code === 200) {
                  ElMessage.success('提交成功')
                  getDetail()
                  store.action.updateIsDetail(true)
                }
              })
            }
          })
        } else {
          isLoading.value = false
          nextTick(() => {
            const element = document.querySelector('.is-error')
            // // 第一种方法（包含动画效果）
            element.scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest，当前显示在视图区域中间
              block: 'center',
              // 值有auto、instant,smooth，缓动动画（当前是慢速的）
              behavior: 'smooth'
            })
          })
        }
      })
    }
    // 会审提交
    const onJointSave = () => {
      planRef.value.validate((valid) => {
        if (valid) {
          const { userid, name } = store.state.user
          const {
            jointReviewAdvantage,
            jointReviewImprovement,
            jointReviewScore
          } = planList.value
          if (jointReviewScore > 150) {
            return ElMessage.error('复合得分最大值为150')
          }
          isLoading.value = true
          jointSave({
            monthPlanId: route.query.id,
            jointPerson: name,
            jointPersonId: userid || route.query.userid,
            jointReviewAdvantage,
            jointReviewImprovement,
            jointReviewScore
          }).then((res) => {
            isLoading.value = false
            if (res.code === 200) {
              store.action.updateIsDetail(true)
              ElMessage.success('提交成功')
              getDetail()
            }
          })
        } else {
          isLoading.value = false
          nextTick(() => {
            const element = document.querySelector('.is-error')
            // // 第一种方法（包含动画效果）
            element.scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest，当前显示在视图区域中间
              block: 'center',
              // 值有auto、instant,smooth，缓动动画（当前是慢速的）
              behavior: 'smooth'
            })
          })
        }
      })
    }
    const open = (e, isJoinAssessor) => {
      title.value = e
      dialogFormVisible.value = true
    }
    const cancel = () => {
      dialogFormVisible.value = false
      formRef.value.resetFields()
    }
    const determine = () => {
      dialogFormVisible.value = false
      const { userid } = store.state.user
      updatePlanState({
        // 19500433631186346
        // 2257151052990977
        approvePersonId: userid || route.query.userid,
        planId: route.query.id,
        remark: form.value.remark,
        checkResult: title.value === '通过' ? 1 : 2
      }).then((res) => {
        if (res.code === 200) {
          store.action.updateIsDetail(true)
          ElMessage.success('审批成功')
          formRef.value.resetFields()
          getDetail()
        }
      })
    }

    onBeforeMount(() => {
      if (route.query.id) {
        getDetail()
      }
    })
    return {
      isLoading,
      isJoinAssessor,
      monthPlanEntity,
      planList,
      planState,
      sum,
      onJointSave,
      onUpdateMonthPlan,
      store,
      open,
      title,
      dialogFormVisible,
      form,
      cancel,
      formRef,
      determine,
      planRef,
      titleType
    }
  }
}
</script>
<style scoped lang="less">
.mobile {
  height: 100vh;
  width: 100vw;
  background: #f7f8f9;
  .fraction {
    color: #fb4343;
    font-size: 24px;
    font-family: Arial, Arial-BoldItalicMT;
    font-weight: 600;
  }
  .jointHearing-img {
    width: 42px;
    height: 42px;
    background: #3296fa;
    line-height: 42px;
    color: #fff;
    font-size: 14px;
    border-radius: 8px;
    img {
      width: 100%;
      border-radius: 8px;
      height: 100%;
    }
  }
  &-header {
    background: #fff;
    padding: 12px 0;

    &-left {
      &-img {
        width: 42px;
        height: 42px;
        background: #3296fa;
        line-height: 42px;
        color: #fff;
        font-size: 14px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    &-employee {
      margin-left: 12px;

      &-name {
        color: #323233;
        font-size: 18px;
        font-weight: 600;
      }

      &-position {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.35);
      }
    }

    &-month {
      color: #323233;
      font-size: 14px;
      font-weight: 600;
    }
  }
  &-content {
    height: calc(100% - 125px);
    overflow: auto;
  }
  .el-form::v-deep {
    .el-form-item__content {
      margin-left: 0;
    }

    .el-form-item__label {
      // width: 100%;
      // text-align: left;
      // color: rgba(0, 0, 0, 0.45);
    }
    .el-input-number__increase,
    .el-input-number__decrease {
      display: block;
    }
    .el-input__inner {
      padding: 0 15px;
      text-align: left;
    }
  }
  &-footer {
    padding: 12px 16px;
  }
}
</style>
